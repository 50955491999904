import React, { useState } from "react";
import LinkedIn from "../../../../public/img/event_speaker_section/LinkedIn.svg";
import facebook from "../../../../public/img/event_speaker_section/facebook.svg";
import twitter from "../../../../public/img/event_speaker_section/twitter.svg";
import website from "../../../../public/img/event_speaker_section/website.svg";
import MinimalSideViewModal from "../GeneralComponents/MinimalSideViewModal";

const SpeakerCardMinimalistic = ({ speaker, disableOnClick }) => {
	const [openModal, setOpenModal] = useState(false);

	const handleClick = () => {
		// Check if onClick should be disabled
		if (!disableOnClick) {
			setOpenModal(prev => !prev);
		}
	};

	return (
		<>
			<div className="speaker-card row m-0 py-3 py-md-2 h-100 d-block d-md-flex ">
				<div
					className="col-12 col-md-5 speaker-image-container mb-3 mb-md-0"
					onClick={handleClick}
				>
					<img src={speaker.image_url} className="speaker-image mx-auto" alt="" />
				</div>
				<div lang="de" className="col-12 col-md-7 speaker-hypen">
					<div onClick={handleClick}>
						<p className="name mt-0 mb-1">{speaker.name}</p>
						<p className="desc2 mt-0 mb-1">{speaker.designation}</p>
						<p className="org mt-0 mb-1">{speaker.organisation}</p>
					</div>
					{(speaker?.linkedin_url ||
						speaker?.facebook_url ||
						speaker?.twitter_url ||
						speaker?.website_url) && (
						<div className="d-flex flex-row mt-3 justify-content-start">
							{speaker?.facebook_url && (
								<div className="facebook_wrapper" style={{ marginRight: 12 }}>
									<a href={speaker.facebook_url} target={"_blank"}>
										<img
											className="facebook"
											src="/img/facebook01.svg"
											alt={"facebook"}
										/>
									</a>
								</div>
							)}
							{speaker?.twitter_url && (
								<div className="twitter_wrapper" style={{ marginRight: 12 }}>
									<a href={speaker.twitter_url} target={"_blank"}>
										<img
											className="twitter"
											src="/img/twitter-mini.svg"
											alt={"twitter"}
										/>
									</a>
								</div>
							)}
							{speaker?.linkedin_url && (
								<div style={{ marginRight: 12 }}>
									<a href={speaker.linkedin_url} target={"_blank"}>
										<img
											className="linkedIn"
											src="/img/linkedin01.svg"
											alt={"Linked In"}
										/>
									</a>
								</div>
							)}
							{speaker?.website_url && (
								<div className="twitter_wrapper">
									<a href={speaker.website_url} target={"_blank"}>
										<img
											className="website"
											src="/img/Website01.svg"
											alt={"website"}
										/>
									</a>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
			<MinimalSideViewModal isOpen={openModal} setIsOpen={setOpenModal} background="#F5F5F5">
				<div className="p-4">
					<div className="text-end mb-3">
						<button
							onClick={() => setOpenModal(false)}
							className="border-0"
							style={{
								background: "#F5F5F5",
								color: "#525252"
							}}
						>
							<i className="fa-solid fa-xmark" style={{ fontSize: "18px" }}></i>
						</button>
					</div>
					<div className="d-flex mb-4 speaker-box">
						<div className="organiser-image-cont-2 rounded-circle me-3">
							<img
								src={speaker.image_url}
								style={{ width: "180px" }}
								alt={`${speaker.name} poster image`}
								className="image-size"
							/>
						</div>
						<div className="d-flex flex-column speaker-about ">
							<p className="speaker-name">{speaker.name}</p>
							<p className="speaker-designation">{speaker.designation}</p>
							<p className="speaker-organisation">{speaker.organisation}</p>
							<div>
								{speaker.facebook_url && (
									<a
										className="me-2"
										href={speaker.facebook_url}
										target={"_blank"}
									>
										<img src="/img/facebook01.svg" alt="" />
									</a>
								)}
								{speaker.twitter_url && (
									<a
										className="me-2"
										href={speaker.twitter_url}
										target={"_blank"}
									>
										<img
											className="twitter-speaker"
											src="/img/twitter-mini.svg"
											alt=""
										/>
									</a>
								)}
								{speaker.linkedin_url && (
									<a
										className="me-2"
										href={speaker.linkedin_url}
										target={"_blank"}
									>
										<img src="/img/linkedin01.svg" alt="" />
									</a>
								)}
								{speaker.website_url && (
									<a
										className="me-2"
										href={speaker.website_url}
										target={"_blank"}
									>
										<img src="/img/Website01.svg" alt="" />
									</a>
								)}
							</div>
						</div>
					</div>

					{speaker.about && (
						<p
							className="mb-0 desc"
							dangerouslySetInnerHTML={{
								__html: `${speaker.about?.replace(/(?:\r\n|\r|\n)/g, "") || ""}`
							}}
						/>
					)}
				</div>
			</MinimalSideViewModal>
			<style jsx>{`
				.speaker-card {
					// cursor: pointer;
					background: #ffffff;
					border-radius: 5px;
					border: 0.8px solid #cacaca;
					text-align: left;
					z-index: 100;
				}
				.speaker-box {
					gap: 20px;
				}
				.speaker-about {
					gap: 10px;
				}
				.speaker-hypen {
					-webkit-hyphens: auto;
					-moz-hyphens: auto;
					-ms-hyphens: auto;
					hyphens: auto;
					word-break: break-word;
				}
				.speaker-name {
					font-family: "Manrope";
					font-weight: 700;
					font-size: 20px;
					color: #1c2520;
				}
				.speaker-designation {
					font-family: "Manrope";
					font-weight: 500;
					font-size: 13px;
				}
				.speaker-organisation {
					font-family: "Manrope";
					font-weight: 500;
					font-size: 13px;
				}
				.icon {
					max-width: 17px;
					margin-top: -25px;
					cursor: pointer;
					-webkit-filter: invert(100%); /* Safari/Chrome */
					filter: invert(100%);
				}
				.speaker-image {
					cursor: pointer;
					border-radius: 5px;
					object-fit: cover;
					width: 100%;
					border: .8px solid #cacaca;
				}
				.name {
					cursor: pointer;
					font-weight: 800;
					margin-top: 5px;
					font-size: 1rem;
					color: #141b25;
				}
				.desc2 {
					cursor: pointer;
				}
				.linkedIn,
				.facebook,
				.twitter,
				.website {
					width: 19px;
					margin-top: -19px;
				}
				.twitter-speaker {
					width: 24px;
					height: 24px;
				}
				.desc {
					font-family: "Manrope" !important;
					font-weight: 400;
					font-size: 13px;
					line-height: 20px;
					color: #000000;
					overflow-wrap: break-word;
				}
				.org {
					cursor: pointer;
					font-style: normal;
					font-weight: 500;
					font-size: 0.875rem;
					color: #141b25;
				}
				.image-size{
					border: .8px solid #cacaca;
				}
				@media (min-width: 992px) and (max-width: 1368px) {
				}
				@media (max-width: 768px) {
					.image-size {
						width: 120px !important;
					}
				}
				@media (max-width: 600px) {
					.speaker-box {
						flex-direction: column;
						align-items: start !important;
					}
					.image-size {
						width: 120px !important;
					}
				}
			`}</style>
		</>
	);
};

export default SpeakerCardMinimalistic;